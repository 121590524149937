<template>
  <div :class="$style.documents">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + 'documents'" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="$router.push('documents/create')"
        >Создать</el-button
      >
    </div>
    <div :class="$style.search">
      <Autocomplete
        :search="search"
        placeholder="Поиск"
        :valueNames="['name']"
        @querySearch="querySearch"
        @selectItem="handleSelectDocument"
        @handleFindItems="handleFindDocuments"
        @input="search = $event"
      />
      <el-button :class="$style.button" type="primary" @click="getList">
        Показать
      </el-button>
    </div>
    <el-table :data="documents" stripe>
      <el-table-column prop="name" label="Название" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="Тип">
        <template slot-scope="scope">
          {{ types.find((type) => type.value === scope.row.type)?.label ?? '' }}
        </template>
      </el-table-column>
      <el-table-column prop="orderField" label="Приоритет"></el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="'documents/' + String(scope.row.id)"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import notifications from '@/mixins/notifications'
import { DOCUMENT_TYPES } from '@/constants/content/documents'
export default {
  mixins: [notifications],
  components: {
    ActionButtons,
    Autocomplete,
  },
  data() {
    return {
      documents: [],
      types: DOCUMENT_TYPES,
      total: 0,
      page: 1,
      limit: 20,
      search: '',
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async handleFindDocuments() {
      await this.getList()
    },
    handleSelectDocument(selectedSeo) {
      this.$router.push('/addwine/content/documents/' + selectedSeo.id)
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ContentServiceCore.DocumentsActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return
      setSearchItems(value.data)
    },
    async getList() {
      const { value, error } =
        await delivery.ContentServiceCore.DocumentsActions.getList({
          limit: this.limit,
          page: this.page,
          search: this.search,
        })

      if (error) return

      this.documents = value.data
      this.total = value.meta.count
    },
    async remove(id) {
      const doc = this.documents.find((doc) => doc.id === id)
      if (confirm(`Вы точно хотите удалить ${doc.name}`)) {
        const { error } =
          await delivery.ContentServiceCore.DocumentsActions.delete(id)

        if (error) return

        this.showNotification('Документ успешно удалён', 'success')
        await this.getList()
      }
    },
  },
}
</script>

<style lang="scss" module>
.documents {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .search {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    input {
      border-radius: 0;
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
